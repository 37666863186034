<template>
  <div>
    <b-card
      no-body
      class="border mt-1"
    >
      <b-table
        sticky-header
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
        :fields="tableFields"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell(opt)="data">
          <b-form-checkbox-group
            v-model="data.item.opt"
            :options="options"
          />
        </template>
      </b-table>
    </b-card>

    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="saving"
      @click="savePermissions"
    >
      {{ saving ? 'Saving' : 'Save Changes' }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      @click="resetData"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BTable, BCard, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'
import useUsersJs from '../users'

const {
  fetchAdminUsers,
  updateAdminUser,
} = useUsersJs()

export default {
  components: {
    BButton,
    BTable,
    BCard,
    BFormCheckboxGroup,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      tableFields: [
        'module',
        'opt',
      ],
      options: [
        { text: 'Read', value: 'read' },
        { text: 'Write', value: 'write' },
        { text: 'Create', value: 'create' },
        { text: 'Delete', value: 'delete' },
      ],
      permissionsData: [],
      permissionsTemplate: [
        { module: 'Analytics.Method', opt: [] },
        { module: 'Analytics.Channel', opt: [] },
        { module: 'Analytics.Payment', opt: [] },
        { module: 'Transaction.List', opt: [] },
        { module: 'Transaction.Download', opt: [] },
        { module: 'Recurring.List', opt: [] },
        { module: 'Waybill.List', opt: [] },
        { module: 'Waybill.Upload', opt: [] },
        { module: 'Waybill.Download', opt: [] },
        { module: 'Merchants.List', opt: [] },
        { module: 'Merchants.Fee', opt: [] },
        { module: 'Merchants.AppList', opt: [] },
        { module: 'Merchants.AppDownload', opt: [] },
        { module: 'Merchants.Kyc', opt: [] },
        { module: 'Channels.List', opt: [] },
        { module: 'Channels.Group', opt: [] },
        { module: 'Channels.Management', opt: [] },
        { module: 'Channels.Swither', opt: [] },
        { module: 'Promotions', opt: [] },
        { module: 'Registration', opt: [] },
        { module: 'Settlement.Manual', opt: [] },
        { module: 'Settlement.Report', opt: [] },
        { module: 'Settlement.Invoice', opt: [] },
        { module: 'Settlement.Payout', opt: [] },
        { module: 'Payments.List', opt: [] },
        { module: 'Payments.Download', opt: [] },
        { module: 'BankBills.Download', opt: [] },
        { module: 'BankBills.Query', opt: [] },
        { module: 'Financials', opt: [] },
        { module: 'AML.ID.List', opt: [] },
        { module: 'AML.ID.BlackList', opt: [] },
        // { module: 'AML.Review.List', opt: [] },
        // { module: 'AML.Review.Download', opt: [] },
        { module: 'AML.Rules.List', opt: [] },
        { module: 'AML.Rules.Group', opt: [] },
        { module: 'Anonymous', opt: [] },
        { module: 'Sanctions', opt: [] },
        // { module: 'AF.TongDun.Policy', opt: [] },
        // { module: 'AF.TongDun.Invoke', opt: [] },
        // { module: 'AF.TongDun.RiskTrend', opt: [] },
        { module: 'Reseller', opt: [] },
        // { module: 'Reseller.Management', opt: [] },
        { module: 'Reseller.KYC', opt: [] },
        { module: 'Calendar', opt: [] },
        { module: 'V1', opt: [] },
        { module: 'Backdoor', opt: [] },
        { module: 'VirtualAccount.List', opt: [] },
        { module: 'VirtualAccount.Download', opt: [] },
      ],
    }
  },
  created() {
    if (!this.userData.permissions) {
      this.userData.permissions = JSON.stringify(this.permissionsTemplate)
    }

    this.resetData()
    const objString = JSON.stringify(this.permissionsTemplate)
    const permissionsTemplate = JSON.parse(objString)

    const newPerms = []
    const perms = JSON.parse(this.userData.permissions)

    permissionsTemplate.forEach(permData => {
      let perm = permData
      perms.forEach(item => {
        const name = item.subject
        const value = item.action
        if (permData.module === name) {
          perm = {
            module: permData.module,
            opt: value,
          }
        }
      })
      newPerms.push(perm)
    })
    this.permissionsData = newPerms
  },
  methods: {
    resetData() {
      const objString = JSON.stringify(this.permissionsTemplate)
      this.permissionsData = JSON.parse(objString)
    },
    fetchUsers() {
      fetchAdminUsers(
        list => {
          this.totalUsers = list.length
        },
        fail => {
          showToast(this, 'Warning', `fetch Users fail with ${fail}.`)
        },
      )
    },
    savePermissions() {
      this.saving = true
      const permissions = []
      this.permissionsData.forEach(item => {
        const name = item.module
        const value = item.opt
        if (!value || value.length < 1) {
          return
        }
        const p = {
          subject: name,
          action: value,
        }
        permissions.push(p)
      })

      // 更新权限
      const detail = {
        uid: this.userData.uid,
        data: {
          permissions,
        },
      }
      updateAdminUser(
        detail,
        () => {
          showToast(this, 'Success', 'Updated')
          this.saving = false
          this.fetchUsers()
        },
        fail => {
          showToast(this, 'Warning', `Update Permissions Fail with ${fail}.`)
          this.saving = false
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
