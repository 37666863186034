<template>
  <div>
    <b-modal
      :visible="isEditModalActive"
      title="User Detail"
      size="xl"
      hide-footer
      centered
      @change="(val) => isEditModalActive = val"
    >
      <user-edit :user-data="userData" />
    </b-modal>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="addNewUser"
            >
              <span class="text-nowrap">Add User</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="users"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="uid"
        show-empty
        empty-text="No matching records found"
        :filter="searchQuery"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="data.item.info.nick_name"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="editUser(data.item)"
            >
              {{ data.item.info.real_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.info.uid }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.info.nick_name }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: action -->
        <template #cell(action)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="KeyIcon"
              size="16"
              class="cursor-pointer"
              @click="resetPwdConfirm(data.item)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="16"
              class="cursor-pointer ml-50"
              @click="deleteConfirm(data.item)"
            />
            <feather-icon
              icon="RotateCcwIcon"
              size="16"
              class="cursor-pointer ml-50"
              @click="resetMfaConfirm(data.item)"
            />
            <feather-icon
              :icon="data.item.status == 1 ? 'PauseIcon' : 'PlayIcon'"
              size="16"
              class="cursor-pointer ml-50"
              @click="toggleStatus(data.item)"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BAvatar, BLink, BFormInput,
  BBadge, BPagination,
} from 'bootstrap-vue'
import { showToast, showAlert } from '@/libs/tool'
import store from '@/store'
import UserEdit from './user-edit/UserEdit.vue'
import useUsersJs from './users'

const {
  fetchAdminUsers,
  deleteAdminUser,
  resetMfa,
  resetPassword,
  toggleStatus,
} = useUsersJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,

    UserEdit,
  },
  data() {
    return {
      tableColumns: [
        'user',
        'email',
        'workplace',
        'status',
        'action',
      ],
      currentPage: 1,
      perPage: 10,
      totalUsers: 0,
      filterOn: ['info', 'uid', 'workplace'],
      searchQuery: '',
      isEditModalActive: false,
      selected: [],
      tableFields: [
        'module',
        'opt',
      ],
      options: [
        { text: 'Read', value: 'read' },
        { text: 'Write', value: 'write' },
        { text: 'Create', value: 'create' },
        { text: 'Delete', value: 'delete' },
      ],
      userData: {
        uid: '',
        avatar: '',
        fullName: '',
        email: '',
        phone: '',
        workplace: '',
      },
    }
  },
  computed: {
    dataMeta() {
      return {
        from: this.perPage * (this.currentPage - 1) + (this.totalUsers ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.totalUsers,
        of: this.totalUsers,
      }
    },
    isSaveDisabled() {
      return this.userData.email === '' || this.userData.phone === '' || this.userData.fullName === ''
    },
    users() {
      return store.state.user.users
    },
  },
  created() {
    this.fetchUsers()
  },
  methods: {
    inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size / 1024 > 64) {
          this.$bvToast.toast('File is too large, Please control the file size within 64k.', {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'warning',
            toaster: 'b-toaster-top-center',
            solid: true,
          })
          return
        }
        const reader = new FileReader()
        reader.onload = e => {
          this.userData.avatar = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    addNewUser() {
      this.isEditModalActive = true
      this.userData = {
        uid: '',
        avatar: '',
        loginName: '',
        name: '',
        phone: '',
        workplace: '',
      }
    },
    editUser(user) {
      this.userData = {
        uid: user.info.uid,
        avatar: user.avatar,
        loginName: user.info.real_name,
        name: user.info.nick_name,
        phone: user.info.uid,
        workplace: user.workplace,
        roles: user.roles ? JSON.parse(user.roles) : '',
        permissions: user.permissions,
        resources: user.resources,
        detail: user.detail,
      }
      this.isEditModalActive = true
    },
    fetchUsers() {
      fetchAdminUsers(
        list => {
          this.totalUsers = list.length
        },
        fail => {
          showToast(this, 'Warning', `fetch Users fail with ${fail}.`)
        },
      )
    },
    onFiltered(filteredItems) {
      this.totalUsers = filteredItems.length
      this.currentPage = 1
    },
    resolveUserStatusVariant(status) {
      if (status === 1) return 'success'
      if (status === 0) return 'warning'
      return 'primary'
    },
    resolveUserStatusText(status) {
      if (status === 1) return 'Enabled'
      if (status === 0) return 'Disabled'
      return 'Other'
    },
    deleteConfirm(user) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteUser(user)
        }
      })
    },
    deleteUser(user) {
      deleteAdminUser(
        user.uid,
        () => {
          showToast(this, 'Success', 'User Deleted')
          this.fetchUsers()
        },
        fail => {
          showToast(this, 'Warning', `User Delete fail with ${fail}.`)
        },
      )
    },
    resetPwdConfirm(user) {
      this.$swal({
        title: `Reset ${user.info.nick_name}'s password?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.resetUserPassword(user)
        }
      })
    },
    resetUserPassword(user) {
      resetPassword(
        user.uid,
        npwd => {
          showAlert(this, 'success', 'Success', `${npwd}`)
          this.fetchUsers()
        },
        fail => {
          showToast(this, 'Warning', `User Password Reset fail with ${fail}.`)
        },
      )
    },
    resetMfaConfirm(user) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.resetUserMfa(user)
        }
      })
    },
    resetUserMfa(user) {
      resetMfa(
        user.uid,
        () => {
          showToast(this, 'Success', 'User MFA Reseted')
          this.fetchUsers()
        },
        fail => {
          showToast(this, 'Warning', `User MFA Reset fail with ${fail}.`)
        },
      )
    },
    toggleStatus(user) {
      toggleStatus(
        user.uid,
        () => {
          showToast(this, 'Success', 'user status updated!')
          this.fetchUsers()
        },
        fail => {
          showToast(this, 'Warning', `user status update fail with ${fail}.`)
        },
      )
    },
  },
}
</script>
