<template>
  <div>
    <b-form>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Merchants" label-for="merchants">
            <v-select
              v-model="merchantFilter"
              :options="merchantOptions"
              :clearable="false"
              :searchable="true"
              :loading="merchantLoading"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="APPs" label-for="apps">
            <v-select
              v-model="appFilter"
              :options="appOptions"
              :clearable="false"
              multiple
              :searchable="true"
              :close-on-select="false"
              :loading="appLoading"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" class="text-center mt-2 mb-2">
          <b-button
            variant="success"
            class="btn-icon"
            @click="addToSelected"
          >
            <feather-icon icon="ChevronsDownIcon" />
          </b-button>
          <b-button
            variant="danger"
            class="btn-icon ml-2"
            @click="removeFromSelected"
          >
            <feather-icon icon="ChevronsUpIcon" />
          </b-button>
        </b-col>

        <b-col v-if="resources && resources.length > 0" cols="12" md="12">
          <b-table
            sticky-header="20rem"
            selectable
            select-mode="multi"
            :items="resources"
            :fields="fields"
            responsive="sm"
            @row-selected="onRowSelected"
          />
        </b-col>
      </b-row>
    </b-form>

    <b-button
      variant="primary"
      class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="saving"
      @click="save"
    >
      {{ saving ? 'Saving' : 'Save Changes' }}
    </b-button>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BForm, BFormGroup, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useUsersJs from '../users'

const {
  fetchMerchants,
  fetchMerchantApps,
  fetchAdminUsers,
  updateAdminUser,
} = useUsersJs()

export default {
  components: {
    BRow, BCol, BButton, BForm, BFormGroup, vSelect, BTable,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      merchantLoading: false,
      merchantFilter: [],
      merchantOptions: [],
      appLoading: false,
      appFilter: [],
      appOptions: [],
      fields: ['merchant_name', 'merchant_no', 'app_name', 'app_id'],
      selected: [],
      resources: [],
    }
  },
  watch: {
    merchantFilter: function fetchMerchantAPPs(merchant) {
      this.appLoading = true
      this.appFilter = []
      this.appOptions = [{ label: 'All', value: '' }]
      fetchMerchantApps(
        merchant.value,
        apps => {
          this.appOptions = apps
          this.appLoading = false
        },
        fail => {
          showToast(this, 'Warning', `fetch APPs fail with ${fail}.`)
        },
      )
    },
  },
  created() {
    this.merchantLoading = true
    fetchMerchants(
      merchants => {
        this.merchantOptions = merchants
        this.merchantLoading = false
      },
      fail => {
        showToast(this, 'Warning', `fetch Merchants fail with ${fail}.`)
      },
    )
    if (this.userData.resources) {
      const resLocal = JSON.parse(this.userData.resources)
      Object.keys(resLocal).forEach(merchant => {
        const localAPPs = resLocal[merchant]
        localAPPs.forEach(app => {
          const item = {
            merchant_no: merchant,
            merchant_name: merchant === '' ? 'All' : merchant,
            app_id: app,
            app_name: app === '' ? 'All' : app,
          }
          this.resources.push(item)
        })
      })
    }
  },
  methods: {
    addToSelected() {
      this.appFilter.forEach(app => {
        const item = {
          merchant_no: this.merchantFilter.value,
          merchant_name: this.merchantFilter.label,
          app_id: app.value,
          app_name: app.label,
        }
        const index = this.resources.filter(r => r.merchant_no === item.merchant_no && r.app_id === item.app_id)
        if (index.length > 0) {
          return
        }
        this.resources.push(item)
      })
    },
    removeFromSelected() {
      this.selected.forEach(item => {
        const removed = this.resources.find(r => r.merchant_no === item.merchant_no && r.app_id === item.app_id)
        const index = this.resources.indexOf(removed)
        if (index > -1) {
          this.resources.splice(index, 1)
        }
      })
    },
    fetchUsers() {
      fetchAdminUsers(
        list => {
          this.totalUsers = list.length
        },
        fail => {
          showToast(this, 'Warning', `fetch Users fail with ${fail}.`)
        },
      )
    },
    onRowSelected(items) {
      this.selected = items
    },
    save() {
      this.saving = true
      const resources = {}
      this.resources.forEach(res => {
        if (!resources[res.merchant_no]) {
          resources[res.merchant_no] = []
        }
        resources[res.merchant_no].push(res.app_id)
      })

      const detail = {
        uid: this.userData.uid,
        data: {
          resources,
        },
      }
      updateAdminUser(
        detail,
        () => {
          showToast(this, 'Success', 'Updated')
          this.saving = false
          this.fetchUsers()
        },
        fail => {
          showToast(this, 'Warning', `Update Permissions Fail with ${fail}.`)
          this.saving = false
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
