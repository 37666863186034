import store from '@/store'
import merchantStoreModule from '@/pagsmile/merchantStoreModule'
import userStoreModule from '../userStoreModule'

export default function usersJs() {
  const USER_STORE_MODULE_NAME = 'user'
  const MERCHANT_STORE_MODULE_NAME = 'merchant'

  // Register module
  if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)

  const fetchAdminUsers = (success, fail) => {
    store
      .dispatch('user/fetchAdminUsers')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          store.commit('user/SET_USERS', data)
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateAdminUser = (params, success, fail) => {
    store
      .dispatch('user/updateAdminUser', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const changePassword = (params, success, fail) => {
    store
      .dispatch('user/changePassword', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const createAdminUser = (params, success, fail) => {
    store
      .dispatch('user/createAdminUser', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateAdminUserDetail = (params, success, fail) => {
    store
      .dispatch('user/updateAdminUserDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const deleteAdminUser = (params, success, fail) => {
    store
      .dispatch('user/deleteAdminUser', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const resetMfa = (uid, success, fail) => {
    store
      .dispatch('user/resetMfa', uid)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const toggleStatus = (uid, success, fail) => {
    store
      .dispatch('user/toggleStatus', uid)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const resetPassword = (uid, success, fail) => {
    store
      .dispatch('user/resetPassword', uid)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchants = (success, fail) => {
    store
      .dispatch('merchant/fetchMerchants')
      .then(response => {
        const { code, data } = response.data

        const merchants = [{ label: 'All', value: '' }]
        if (code === '10000') {
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })
        }

        success(merchants)
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantApps = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/fetchMerchantApps', merchantNo)
      .then(response => {
        const { code, data } = response.data

        const apps = [{ label: 'All', value: '' }]
        if (code === '10000') {
          data.forEach(item => {
            const app = { label: item.app_name, value: item.app_id }
            apps.push(app)
          })
        }
        success(apps)
      })
      .catch(() => {
        fail('error')
      })
  }

  return {
    fetchAdminUsers,
    updateAdminUser,
    changePassword,
    createAdminUser,
    updateAdminUserDetail,
    deleteAdminUser,
    resetMfa,
    toggleStatus,
    resetPassword,
    fetchMerchants,
    fetchMerchantApps,
  }
}
