<template>
  <div>
    <b-media v-if="userData.uid != ''" class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="userData.avatar = ''"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <b-form>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Login Name" label-for="login-name">
            <b-form-input id="login-name" v-model="userData.loginName" :disabled="userData.uid != ''" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="name">
            <b-form-input id="name" v-model="userData.name" :disabled="userData.uid != ''" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="User Role" label-for="user-role">
            <v-select
              v-model="userData.roles"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              multiple
              :searchable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Mobile" label-for="mobile">
            <VuePhoneNumberInput
              id="mobile"
              v-model="userDataSocial.national_number"
              :preferred-countries="['BR', 'CN', 'MX']"
              :default-country-code="defaultCountryCode"
              @update="mobileUpdated"
            />
          </b-form-group>
        </b-col>

        <!-- Workplace -->
        <b-col cols="12" md="4">
          <b-form-group
            label="Workplace"
            label-for="workplace"
          >
            <v-select
              v-model="userData.workplace"
              :options="countryOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="true"
              input-id="workplace"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-for="socialField in socialInputs"
          :key="socialField.dataField"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="socialField.label"
            :label-for="socialField.dataField"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  size="16"
                  :icon="socialField.icon"
                />
              </b-input-group-prepend>
              <b-form-input
                :id="socialField.dataField"
                v-model="userDataSocial[socialField.dataField]"
                type="url"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <b-button
      variant="primary"
      class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="saving || !userData.workplace || userData.workplace === '' || userData.loginName === '' || userData.name === '' || !mobileDetail.isValid || userDataSocial.email === ''"
      @click="save"
    >
      {{ saving ? 'Saving' : 'Save Changes' }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { avatarText } from '@core/utils/filter'
import countries from '@/assets/json/countries.json'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useUsersJs from '../users'

const {
  fetchAdminUsers,
  createAdminUser,
  updateAdminUser,
} = useUsersJs()

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    VuePhoneNumberInput,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      countries,
      countryOptions: [],
      saving: false,
      avatarUpdated: false,
      userDataSocial: {
        nick_name: '',
        phone: '',
        wechat: '',
        email: '',
        whatsapp: '',
        skype: '',
        country_code: '',
        national_number: '',
      },
      socialInputs: [
        {
          icon: 'MailIcon',
          dataField: 'email',
          label: 'E-Mail',
        },
        {
          icon: 'MessageCircleIcon',
          dataField: 'wechat',
          label: 'WeChat',
        },
        {
          icon: 'MicIcon',
          dataField: 'whatsapp',
          label: 'WhatsAPP',
        },
        {
          icon: 'VideoIcon',
          dataField: 'skype',
          label: 'Skype',
        },
      ],
      roleOptions: [
        { label: 'Account Manager', value: 'AM' },
        { label: 'Business Manager', value: 'BM' },
        { label: 'Customer Service', value: 'CS' },
        { label: 'Distributor', value: 'DI' },
        { label: 'Financial', value: 'FD' },
        { label: 'Developer', value: 'RD' },
        { label: 'Administrator', value: 'AD' },
      ],
      mobileDetail: {},
      defaultCountryCode: '',
    }
  },
  created() {
    if (!this.userData.detail) {
      return
    }
    this.userDataSocial = JSON.parse(this.userData.detail)
    this.defaultCountryCode = this.userDataSocial.country_code
  },
  mounted() {
    const merchantCountryList = []
    this.countries.forEach(item => {
      const merchantCountry = { label: item.name.official, value: item.cca3 }
      merchantCountryList.push(merchantCountry)
    })
    this.countryOptions = merchantCountryList
  },
  methods: {
    mobileUpdated(value) {
      this.mobileDetail = value
    },
    inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size / 1024 > 64) {
          this.$bvToast.toast('File is too large, Please control the file size within 64k.', {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'warning',
            toaster: 'b-toaster-top-center',
            solid: true,
          })
          return
        }
        const reader = new FileReader()
        reader.onload = e => {
          this.avatarUpdated = true
          this.userData.avatar = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    fetchUsers() {
      fetchAdminUsers(
        list => {
          this.totalUsers = list.length
        },
        fail => {
          showToast(this, 'Warning', `fetch Users fail with ${fail}.`)
        },
      )
    },
    save() {
      this.saving = true
      this.userDataSocial.nick_name = this.userData.name
      this.userDataSocial.phone = `${this.mobileDetail.countryCallingCode}${this.mobileDetail.nationalNumber}`
      this.userDataSocial.country_code = this.mobileDetail.countryCode
      this.userDataSocial.national_number = this.mobileDetail.nationalNumber
      const detail = {
        detail: this.userDataSocial,
        workplace: this.userData.workplace,
        roles: this.userData.roles,
      }
      if (this.avatarUpdated) {
        detail.avatar = this.userData.avatar
      }
      if (!this.userData.uid || this.userData.uid === '') {
        // 新建用户
        const params = {
          real_name: this.userData.loginName,
          nick_name: this.userData.name,
          password: this.userDataSocial.phone,
          detail,
        }
        createAdminUser(
          params,
          uid => {
            showToast(this, 'Success', `${uid} Created`)
            this.fetchUsers()
            this.saving = false
          },
          fail => {
            showToast(this, 'Warning', `Create Fail with ${fail}.`)
            this.saving = false
          },
        )
      } else {
        // 更新权限
        const params = {
          uid: this.userData.uid,
          data: detail,
        }
        updateAdminUser(
          params,
          () => {
            showToast(this, 'Success', 'Updated')
            this.fetchUsers()
            this.saving = false
          },
          fail => {
            showToast(this, 'Warning', `Update Permissions Fail with ${fail}.`)
            this.saving = false
          },
        )
      }
    },
    avatarText,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
